<template>
  <a-modal
    v-model="batchCopySourceVisible"
    title="选择配置分组"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="800"
  >
    <a-alert
      style="font-size: 12px; margin: 0 20px"
      message="选择将该广告源配置到对应的流量分组；如不配置，则默认配置到该广告位下流量分组中的默认分组。"
      banner
    />
    <a-form-model class="batch_copy_source_form" ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
      <a-form-model-item
        :rules="[{ required: true, message: '请选择流量分组/测试分组', trigger: 'change' }]"
        prop="testList"
      >
        <div class="group_wrapper">
          <div class="group_box">
            <div class="box_header">
              <span>流量分组</span>
            </div>
            <div class="box_list">
              <div class="group_item" v-if="groups.length > 0">
                <a-checkbox
                  style="font-size: 12px"
                  :indeterminate="indeterminate"
                  :checked="checkAll"
                  @change="handleCheckAll"
                >
                  全部
                </a-checkbox>
              </div>
              <div
                :class="{ group_item: true, active: currentGroupId === item.id }"
                v-for="(item, index) in groups"
                :key="item.id"
              >
                <a-checkbox
                  style="font-size: 12px"
                  :checked="item.checkAll"
                  :indeterminate="item.indeterminate"
                  @change="checkGroupItem($event, item, index)"
                >
                </a-checkbox>
                <div class="group_name" @click="handleClickGroupName(item)">
                  <div class="name">
                    <div class="tip" :title="`流量占比：${getRate(item)}%`">
                      <RequestPieTip :rate="getRate(item)"/>
                    </div>
                    {{ item.name }}
                  </div>
                  <span class="right"><a-icon type="right" /></span>
                </div>
              </div>
              <a-empty style="margin-top: 30px" v-if="groups.length === 0" />
            </div>
          </div>
          <div class="test_box">
            <div class="box_header">
              <span>测试分组</span>
            </div>
            <div class="box_list">
              <div class="test_item" v-if="currentTestList.length > 0">
                <a-checkbox
                  style="font-size: 12px"
                  :indeterminate="indeterminateTest"
                  :checked="checkAllTest"
                  @change="handleCheckAllTest"
                >
                  全部
                </a-checkbox>
              </div>
              <div class="test_item" v-for="(item, index) in currentTestList" :key="index">
                <a-checkbox
                  style="font-size: 12px"
                  :checked="item.checked"
                  @change="checkTestItem($event, item, index)"
                >
                  <span :title="'流量请求数：' + item.request">{{ item.name }}</span>
                </a-checkbox>
              </div>
              <a-empty style="margin-top: 30px" v-if="currentTestList.length === 0" />
            </div>
          </div>
          <div class="checked_box">
            <div class="box_header">
              <span>已选择{{ formquery.testList.length }}项</span>
            </div>
            <div class="box_list">
              <div
                v-show="formquery.testList.includes(item.id)"
                :title="item.name"
                class="checked_item"
                v-for="(item, index) in allTestList"
                :key="index"
              >
                <span class="name">{{ item.name }}</span>
                <span @click="deleteTestItem(item.id)" class="delete"><a-icon type="close" /></span>
              </div>
            </div>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getRtaGroupIdListByPlace } from '@/api/aggregate'
import RequestPieTip from '../../../../Config/components/RequestPieTip'
export default {
  name: 'SelectTestModal',
  components: { RequestPieTip },
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      rules: {},
      groups: [],
      formquery: {
        testList: []
      },
      currentGroupId: undefined,
      currentTestList: [],
      allTestList: [],
      totalRequest: 0
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sourceList: {
      default: () => [],
      type: Array
    },
    query: {
      default: () => ({}),
      type: Object
    },
    placeConfig: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    sourceList: {
      handler (val) {},
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 弹窗显示
    batchCopySourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    // 所有测试分组是否选择
    indeterminate () {
      return this.formquery.testList.length > 0 && this.formquery.testList.length < this.allTestList.length
    },
    // 所有测试分组是否全选
    checkAll () {
      return this.formquery.testList.length === this.allTestList.length
    },
    // 当前测试分组是否选择
    indeterminateTest () {
      return this.currentTestList.some((item) => item.checked) && !this.currentTestList.every((item) => item.checked)
    },
    // 当前测试分组是否全选
    checkAllTest () {
      return this.currentTestList.every((item) => item.checked)
    }
  },
  created () {
    this.getGroups()
  },
  methods: {
    setCheckoutTestList () {
      this.formquery.testList = []
      // 获取已配置的testList
      const { groupList = [], placeId } = this.placeConfig
      if (!placeId) return
      groupList.forEach((group) => {
        const { testIdList = [], groupId } = group
        testIdList.forEach((test) => {
          if (test === 'default') {
            this.formquery.testList.push(groupId + test)
          } else {
            this.formquery.testList.push(test)
          }
        })
      })
      // 页面选择
      this.groups.forEach((group, index) => {
        const { children = [] } = group
        this.currentGroupId = group.id
        this.currentTestList = children
        children.forEach((test, tIndex) => {
          if (this.formquery.testList.includes(test.id)) {
            this.checkTestItem({
              target: {
                checked: true
              }
            }, test, tIndex)
          }
        })
      })
      this.formquery.testList = [...new Set(this.formquery.testList)]
      this.currentGroupId = ''
      this.currentTestList = []
      this.changeGroups()
    },
    getRate (item) {
      const { request = 0 } = item
      return request / (this.totalRequest || 1) * 100
    },
    // 删除单个已选分组
    deleteTestItem (id) {
      this.formquery.testList = this.formquery.testList.filter((item) => item !== id)
      this.changeGroups()
    },
    // 选择全部当前测试分组
    handleCheckAllTest (e) {
      if (e.target.checked) {
        this.currentTestList.forEach((item) => {
          if (!this.formquery.testList.includes(item.id)) {
            this.formquery.testList.push(item.id)
          }
        })
      } else {
        this.currentTestList.forEach((item) => {
          if (this.formquery.testList.includes(item.id)) {
            this.formquery.testList = this.formquery.testList.filter((child) => child !== item.id)
          }
        })
      }
      this.changeGroups()
    },
    // 选择全部测试分组
    handleCheckAll (e) {
      if (e.target.checked) {
        this.formquery.testList = this.allTestList.map((item) => item.id)
      } else {
        this.formquery.testList = []
      }
      this.changeGroups()
    },
    // 选择流量分组
    checkGroupItem (e, item, index) {
      if (e.target.checked) {
        item.children.forEach((child) => {
          if (!this.formquery.testList.includes(child.id)) {
            this.formquery.testList.push(child.id)
          }
        })
      } else {
        item.children.forEach((gitem) => {
          this.formquery.testList = this.formquery.testList.filter((child) => child !== gitem.id)
        })
      }
      this.changeGroups()
      this.currentGroupId = item.id
      this.currentTestList = this.groups[index].children
    },
    // 点击流量分组名称
    handleClickGroupName (item) {
      this.currentGroupId = item.id
      this.currentTestList = item.children
    },
    // 选择单个测试分组
    checkTestItem (e, info, index) {
      this.currentTestList[index].checked = e.target.checked
      if (e.target.checked) {
        this.formquery.testList.push(info.id)
      } else {
        this.formquery.testList = this.formquery.testList.filter((item) => item !== info.id)
      }
      this.changeGroups()
    },
    // 修改流量分组信息
    changeGroups () {
      this.groups.forEach((item) => {
        item.indeterminate = false
        item.checkAll = false
        let num = 0
        item.children.forEach((child, c) => {
          child.checked = false
          if (this.formquery.testList.includes(child.id)) {
            child.checked = true
            num++
          }
        })
        num === item.children.length && (item.checkAll = true)
        !item.checkAll && num > 0 && (item.indeterminate = true)
      })
    },
    async getGroupList () {
      const { data = {} } = await getRtaGroupIdListByPlace({
        placeId: this.query.placeId,
        dspId: this.query.id
      })
      const { groups = [], totalRequest = 0 } = data
      this.totalRequest = totalRequest
      return groups
    },
    // 获取流量分组信息
    getGroups () {
      const groups = []
      this.getGroupList().then((g) => {
        g.forEach((item, i) => {
          groups.push({
            name: item.name,
            id: item.id,
            children: [],
            indeterminate: false,
            checkAll: false,
            request: item.request || 0
          })
          item.abTests.forEach((child, c) => {
            if (child.sts === 'A') {
              if (item.abTests.length === 1) {
                groups[i].children.push({
                  name: child.groupName,
                  id: item.id + child.testId,
                  checked: false,
                  request: child.request
                })
                this.allTestList.push({
                  name: item.name + '-' + child.groupName,
                  id: item.id + child.testId,
                  request: child.request
                })
              } else {
                child.testId !== 'default' &&
                  groups[i].children.push({
                    name: child.groupName,
                    id: child.testId,
                    checked: false,
                    request: child.request
                  })
                child.testId !== 'default' &&
                  this.allTestList.push({
                    name: item.name + '-' + child.groupName,
                    id: child.testId,
                    request: child.request
                  })
              }
            }
          })
        })
        this.groups = groups
        this.setCheckoutTestList()
      })
    },
    // 弹窗关闭
    handleCancel () {
      this.batchCopySourceVisible = false
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const target = []
          this.groups.forEach((item) => {
            const arr = item.children.filter((c) => c.checked).map((child) => child.id)
            arr.forEach((tItem, tIndex) => {
              const reg1 = /^default/
              const reg2 = /default$/
              reg1.test(tItem) && (arr[tIndex] = tItem.replace(/default/, ''))
              reg2.test(tItem) && (arr[tIndex] = 'default')
            })
            const targetItem = {
              groupId: item.id
            }
            arr.length > 0 && (targetItem.testIdList = arr) && target.push(targetItem)
          })
          this.$emit('handleSelectTest', target)
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.batch_copy_source_form {
  .group_wrapper {
    user-select: none;
    width: 700px;
    height: 300px;
    margin-left: 40px;
    display: flex;
    padding: 10px;
    .group_box,
    .test_box,
    .checked_box {
      border: 1px solid #ccc;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      .box_header {
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        background: #fafafa;
        border-bottom: 1px solid #ccc;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
      }
      .box_list {
        flex-grow: 1;
        height: 0;
        padding: 0 5px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          background: fade(@primary-color, 20%);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
        .group_item,
        .test_item {
          font-size: 12px;
          height: 36px;
          line-height: 36px;
          padding: 0 5px;
          display: flex;
          cursor: pointer;
          .group_name {
            flex-grow: 1;
            padding-left: 10px;
          }
          &.active {
            color: @primary-color;
          }
        }
        .group_name {
          display: flex;
          justify-content: space-between;
          .name {
            display: flex;
            align-items: center;
            .tip{
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
            flex-grow: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .checked_item {
          cursor: pointer;
          height: 36px;
          line-height: 36px;
          margin: 3px 0;
          padding: 0 5px;
          background: fade(@primary-color, 1%);
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          .name {
            flex-grow: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .group_box,
    .test_box {
      min-width: 230px;
      margin-right: 5px;
    }
    .checked_box {
      min-width: 208px;
      flex-grow: 1;
    }
  }
}
</style>
