<template>
  <div ref="chart" class="chart-container" />
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Chart',
  props: {
    option: {
      default: () => ({}),
      type: Object
    }
  },
  data () {
    return {
      myEcharts: null
    }
  },
  watch: {
    option: {
      handler (val) {
        this.$nextTick(() => {
          this.initChart()
        })
      },
      deep: true,
      immediate: true
    }
  },
  created () {},
  mounted () {
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
    window.addEventListener('resize', this.getSize)
    this.initChart()
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    initChart () {
      this.myEcharts.setOption(this.option, true, { render: 'svg' })
    },
    getSize () {
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    }
  }
}
</script>

<style scoped lang='less'>
.chart-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
