<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-07 11:27:22
 * @FilePath: /mediatom-web/src/views/aggregate/Config/components/RequestPieTip/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="chart">
    <Chart :option="option" />
  </div>
</template>

<script>
import Chart from '@/components/common/Chart'
export default {
  components: { Chart },
  data () {
    return {
      option: {}
    }
  },
  props: {
    rate: {
      default: 0,
      type: Number
    }
  },
  watch: {
    rate: {
      handler (val) {
        this.option = {
          title: {
            show: false
          },
          tooltip: {
            show: false,
            trigger: 'item'
          },
          legend: {
            show: false,
            orient: 'vertical',
            left: 'left'
          },
          series: [
            {
              name: '',
              type: 'pie',
              data: [
                {
                  value: 100 - val,
                  name: '',
                  itemStyle: {
                    color: 'orange'
                  }
                },
                {
                  value: val,
                  name: '',
                  itemStyle: {
                    color: '#3366ff'
                  }
                }
              ],
              itemStyle: {
                borderRadius: 0,
                borderColor: '#fff',
                borderWidth: 1
              },
              radius: '90%',
              labelLine: false,
              emphasis: {
                scale: false
              }
            }
          ]
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.chart {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
</style>
